import { AppData } from 'src/app/data';

import { RouteFeatures } from '../routes/types';

export const getRouteFeaturesFromAppData = (
  appData: AppData
): RouteFeatures => ({
  isWorkspaceSettingsIndexEnabled:
    appData.features['spa-ws-settings--settings-index'],
  isNavigationResourceSwitchEnabled:
    appData.features['navigation-resource-switch'],
  isRepoAdminAliasesEnabled:
    appData.features['spa-repo-settings--username-aliases'],
  isRepoAdminComplianceEnabled:
    appData.features['spa-repo-settings--compliance'],
  isPipelinesTestManagementEnabled:
    appData.features['pipelines-test-management'],
  isAccountSettingsSSHKeysEnabled:
    appData.features['spa-account-settings--settings-ssh-keys'],
  // eslint-disable-next-line dot-notation
  isFrontbucketDownloadsEnabled: appData.features['frontbucket_downloads'],
  isFrontbucketBranchCompareEnabled:
    appData.features.frontbucket_branch_compare,
  isRepoSourceFileEditEnabled:
    appData.features.frontbucket_repo_source_file_edit,
  isRepoSourceFileAnnotateEnabled:
    appData.features.frontbucket_repo_source_file_annotate,
  isRepoSourceAddFileEnabled: appData.features.frontbucket_repo_source_add_file,
  isAutoreviewSettingsPageEnabled:
    appData.features.autoreview_repository_settings_page,
  isRepoIssuesEnabled: appData.features.frontbucket_repo_issues,
});
