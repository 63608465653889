import { legacyPath } from 'src/sections/repository/utils/get-legacy-path';
import { REPO_FULL_SLUG_REGEX } from 'src/utils/validate-repository-path';

import { ROUTE_NAME, RouteFeatures, SupportedPath } from '../types';

// passes `repositoryFullSlug` param
const repoPath = (path: string) => `/${REPO_FULL_SLUG_REGEX}/${path}`;

export const getSupportedPaths = (features: RouteFeatures): SupportedPath[] => {
  const {
    isRepoAdminAliasesEnabled,
    isRepoAdminComplianceEnabled,
    isFrontbucketDownloadsEnabled,
    isPipelinesTestManagementEnabled,
    isRepoSourceAddFileEnabled,
    isRepoIssuesEnabled,
    isAutoreviewSettingsPageEnabled,
  } = features;

  const paths: SupportedPath[] = [
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_CREATE_WEBHOOK,
      path: legacyPath('admin/webhooks/new'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_EDIT_WEBHOOK,
      path: legacyPath('admin/webhooks/:webhookUuid/edit'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_VIEW_WEBHOOK_EVENT,
      path: legacyPath('admin/webhooks/:webhookUuid/event/:eventId'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_VIEW_WEBHOOK,
      path: legacyPath('admin/webhooks/:webhookUuid'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_WEBHOOKS_ROOT,
      path: legacyPath('admin/webhooks'),
      exact: false,
    },
  ];

  paths.push({
    name: ROUTE_NAME.REPOSITORY_CREATE_PR,
    path: legacyPath('pull-requests/new'),
    exact: false,
  });

  paths.push({
    name: ROUTE_NAME.REPOSITORY_CODE_REVIEW,
    path: legacyPath(
      'pull-requests/:pullRequestId/:tab(overview|diff|commits)?'
    ),
    exact: false,
  });

  paths.push({
    name: ROUTE_NAME.REPOSITORY_BRANCH_DETAIL,
    path: legacyPath('branch/:ref+'),
    exact: false,
  });

  // settings pages
  paths.push(
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_DETAIL,
      path: legacyPath('admin'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_PERMISSIONS,
      path: legacyPath('admin/permissions'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_ACCESS_KEYS,
      path: legacyPath('admin/access-keys'),
      exact: true,
    }
  );

  if (isRepoSourceAddFileEnabled) {
    paths.push({
      name: ROUTE_NAME.REPOSITORY_SOURCE_ADD_FILE,
      path: legacyPath('create-file/:refOrHash?/:path*'),
    });
  }

  if (isRepoIssuesEnabled) {
    paths.push({
      name: ROUTE_NAME.REPOSITORY_ISSUES,
      path: legacyPath('issues'),
      exact: true,
    });

    paths.push({
      name: ROUTE_NAME.REPOSITORY_SINGLE_ISSUE,
      path: legacyPath('issues/:path*'),
    });

    paths.push({
      name: ROUTE_NAME.REPOSITORY_CREATE_ISSUE,
      path: legacyPath('issues/new'),
      exact: true,
    });
  }

  if (isRepoAdminAliasesEnabled) {
    paths.push({
      name: ROUTE_NAME.REPOSITORY_ADMIN_COMMITTERS,
      path: legacyPath('admin/committers'),
      exact: true,
    });
  }

  if (isRepoAdminComplianceEnabled) {
    paths.push({
      name: ROUTE_NAME.REPOSITORY_ADMIN_COMPLIANCE,
      path: legacyPath('admin/compliance'),
      exact: true,
    });
  }
  if (isFrontbucketDownloadsEnabled) {
    paths.push({
      name: ROUTE_NAME.REPOSITORY_DOWNLOADS,
      path: legacyPath('downloads'),
    });
  }

  paths.push({
    name: ROUTE_NAME.REPOSITORY_ADMIN_ACCESS_TOKENS,
    path: legacyPath('admin/access-tokens'),
    exact: true,
  });

  if (isAutoreviewSettingsPageEnabled) {
    paths.push({
      name: ROUTE_NAME.REPOSITORY_ADMIN_FEATURES_AUTOREVIEW,
      path: legacyPath('admin/autoreview'),
      exact: true,
    });
  }

  paths.push(
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_REDIRECT_SETTINGS,
      path: legacyPath(
        'admin/addon/admin/:appKey(pipelines)/:moduleKey(settings|openid-connect|ssh-keys|integrations|repository-variables)'
      ),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_REDIRECT_RUNNERS,
      path: legacyPath(
        'admin/addon/admin/:appKey(pipelines)/:moduleKey(runners)'
      ),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_REDIRECT_DEPLOYMENTS,
      path: legacyPath(
        'admin/addon/admin/:appKey(pipelines)/:moduleKey(deployment-settings)'
      ),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_SETTINGS,
      path: legacyPath('admin/pipelines/:path(settings)'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_OPENID,
      path: legacyPath('admin/pipelines/:path(openid-connect)'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_SSH_KEYS,
      path: legacyPath('admin/pipelines/:path(ssh-keys)'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_RUNNERS,
      path: legacyPath('admin/pipelines/:path(runners)'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_DEPLOYMENTS,
      path: legacyPath('admin/pipelines/:path(deployment-settings)'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_VARIABLES,
      path: legacyPath('admin/pipelines/:path(repository-variables)'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_DYNAMIC_PIPELINES,
      path: legacyPath('admin/pipelines/:path(dynamic-pipelines)'),
      exact: true,
    }
  );

  paths.push(
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_FORGE_PAGE,
      path: legacyPath('admin/forge/:appId/:moduleKey'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_FORGE_PAGE,
      path: legacyPath('admin/forge/:appId/:envKey/:moduleKey/:modulePath*'),
      exact: true,
    }
  );

  paths.push(
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_BRANCH_RESTRICTIONS,
      path: legacyPath('admin/branch-permissions'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_BRANCH_RESTRICTIONS,
      path: legacyPath('admin/branch-restrictions'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_BRANCHING_MODEL,
      path: legacyPath('admin/branching-model'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_MERGE_CHECKS,
      path: legacyPath('admin/merge-checks'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_MERGE_STRATEGIES,
      path: legacyPath('admin/merge-strategies'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_DEFAULT_REVIEWERS,
      path: legacyPath('admin/pullrequests/reviewers'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_REVIEW_GROUPS,
      path: legacyPath('admin/pullrequests/review-groups'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_ISSUES_MIGRATION,
      path: legacyPath('admin/issues/jira-migration'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_CONNECT_IFRAME,
      path: legacyPath('admin/addon/admin/:appKey/:moduleKey'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_CONNECT_IFRAME,
      path: legacyPath('admin/addon/admin/:appKey/:moduleKey'),
    },
    {
      name: ROUTE_NAME.REPOSITORY_ADMIN_IFRAME,
      path: legacyPath('admin/:path*'),
    }
  );

  // repo pages
  paths.push(
    {
      name: ROUTE_NAME.REPOSITORY_SOURCE,
      path: legacyPath('src/:refOrHash?/:path*'),
    },
    {
      name: ROUTE_NAME.REPOSITORY_SOURCE_DIFF,
      path: repoPath('diff/:path+'),
    },
    {
      name: ROUTE_NAME.REPOSITORY_BRANCH_CREATE,
      path: legacyPath('branch'),
    },
    {
      name: ROUTE_NAME.REPOSITORY_BRANCHES_LIST,
      path: legacyPath('branches'),
    },
    {
      name: ROUTE_NAME.REPOSITORY_PULL_REQUESTS,
      path: legacyPath('pull-requests'),
    },
    {
      name: ROUTE_NAME.REPOSITORY_COLD_STORAGE,
      path: legacyPath('cold-storage'),
    },
    {
      name: ROUTE_NAME.REPOSITORY_SECURITY,
      path: legacyPath('security'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_SECURITY_LEARN_MORE,
      path: legacyPath('security/learn-more'),
    },

    ...[
      repoPath('commits'),
      repoPath('commits/all'),
      repoPath('commits/(branch|tag)/:refName(.*)'),
    ].map(path => ({
      name: ROUTE_NAME.REPOSITORY_COMMITS,
      path,
      exact: true,
    })),
    {
      name: ROUTE_NAME.REPOSITORY_COMMITS_HISTORY,
      path: repoPath('commits/history'),
    }
  );

  // commit page
  paths.push({
    name: ROUTE_NAME.REPOSITORY_COMMIT,
    path: legacyPath('commits/:ref+'),
    exact: false,
  });

  paths.push(
    ...[
      repoPath('deployments/environments/:environmentUuid/:deploymentUuid'),
      repoPath('deployments/environments/:environmentUuid'),
      repoPath('deployments/:deploymentUuid'),
      repoPath('deployments'),
    ].map(path => ({
      name: ROUTE_NAME.REPOSITORY_DEPLOYMENTS,
      path,
    }))
  );

  paths.push(
    ...[
      repoPath('pipelines'),
      repoPath('pipelines/filters/:filters'),
      repoPath('pipelines/results'),
    ].map(path => ({
      name: ROUTE_NAME.REPOSITORY_PIPELINES,
      exact: true,
      path,
    }))
  );

  paths.push(
    ...[
      repoPath('pipelines/results/:pipelineUuid'),
      repoPath('pipelines/results/:pipelineUuid/steps/:stepUuid'),
      repoPath('pipelines/results/:pipelineUuid/from-email'),
      repoPath('pipelines/results/:pipelineUuid/runs/:pipelineRunUuid'),
      repoPath(
        'pipelines/results/:pipelineUuid/runs/:pipelineRunUuid/steps/:stepUuid'
      ),
    ].map(path => ({
      name: ROUTE_NAME.REPOSITORY_PIPELINES_RESULT,
      exact: true,
      path,
    }))
  );

  paths.push(
    ...[
      repoPath('pipelines/results/filters/:filters'),
      repoPath('pipelines/results/branch/:branch/page/:page/filters/:filters'),
      repoPath('pipelines/results/page/:page/filters/:filters'),
      repoPath('pipelines/results/branch/:branch/page/:page'),
      repoPath('pipelines/results/page/:page/'),
    ].map(path => ({
      name: ROUTE_NAME.REPOSITORY_PIPELINES,
      path,
    }))
  );

  paths.push(
    ...[
      repoPath(
        'pipelines/results/:pipelineUuid/steps/:stepUuid/trigger/:trigger'
      ),
      repoPath(
        'pipelines/results/:pipelineUuid/steps/:stepUuid/services/:serviceUuid'
      ),
      repoPath(
        'pipelines/results/:pipelineUuid/runs/:pipelineRunUuid/steps/:stepUuid/services/:serviceUuid'
      ),
      repoPath('pipelines/results/:pipelineUuid/steps/:stepUuid/(.*)'),
      repoPath(
        'pipelines/results/:pipelineUuid/steps/:stepUuid/runs/:pipelineRunUuid/(.*)'
      ),
      repoPath(
        'pipelines/results/:pipelineUuid/runs/:pipelineRunUuid/steps/:stepUuid/(.*)'
      ),
    ].map(path => ({
      name: ROUTE_NAME.REPOSITORY_PIPELINES_RESULT,
      path,
    }))
  );

  if (isPipelinesTestManagementEnabled) {
    paths.push(
      ...[repoPath('test-cases')].map(path => ({
        name: ROUTE_NAME.REPOSITORY_TEST_MANAGEMENT,
        exact: true,
        path,
      }))
    );

    paths.push(
      ...[repoPath('test-cases/:testUuid')].map(path => ({
        name: ROUTE_NAME.REPOSITORY_TEST_EXECUTION,
        exact: true,
        path,
      }))
    );
  }

  paths.push(
    {
      name: ROUTE_NAME.REPOSITORY_FORGE_PAGE,
      path: legacyPath('forge/:appId/:moduleKey'),
      exact: true,
    },
    {
      name: ROUTE_NAME.REPOSITORY_FORGE_PAGE,
      path: legacyPath('forge/:appId/:envKey/:moduleKey/:modulePath*'),
      exact: true,
    }
  );

  paths.push(
    ...[
      legacyPath('history/:path+'),
      legacyPath('history-node/:refOrHash/:path+'),
    ].map(path => ({
      name: ROUTE_NAME.REPOSITORY_FILE_HISTORY,
      path,
    })),
    {
      name: ROUTE_NAME.REPOSITORY_CONNECT_PAGE,
      path: legacyPath('addon/:appKey/:moduleKey'),
    },
    {
      name: ROUTE_NAME.REPOSITORY_JIRA_VIEW,
      path: legacyPath('jira'),
    },
    {
      name: ROUTE_NAME.REPOSITORY_SOURCE,
      path: legacyPath('admin'),
    },
    {
      name: ROUTE_NAME.REPOSITORY_REDIRECT,
      path: `/${REPO_FULL_SLUG_REGEX}`,
    }
  );

  return paths;
};
