import React from 'react';

import { defineMessages, FormattedMessage } from 'react-intl';

import {
  ProfileCardClientData,
  UserProfileClient,
} from '@atlaskit/profilecard';

import { AppUser, AppUserKind } from 'src/components/types';

import messages from './i18n';

export const appUserKindMessages = defineMessages({
  repositoryAccessToken: {
    id: 'frontbucket.components.userProfile.appUserKind.repositoryAccessToken',
    description:
      'Text for the "Access token" app user kind defined in repository scope',
    defaultMessage: 'Access token (repository)',
  },
  projectAccessToken: {
    id: 'frontbucket.components.userProfile.appUserKind.projectAccessToken',
    description:
      'Text for the "Access token" app user kind defined in project scope',
    defaultMessage: 'Access token (project)',
  },
  workspaceAccessToken: {
    id: 'frontbucket.components.userProfile.appUserKind.workspaceAccessToken',
    description:
      'Text for the "Access token" app user kind defined in workspace scope',
    defaultMessage: 'Access token (workspace)',
  },
  forgeApp: {
    id: 'frontbucket.components.userProfile.appUserKind.forgeApp',
    description: 'Text for Forge app user kind',
    defaultMessage: 'Forge app',
  },
  unknownAppUser: {
    id: 'frontbucket.components.userProfile.appUserKind.unknownAppUser',
    description: 'Text for an unknown app user kind',
    defaultMessage: 'Unknown app user',
  },
});

type BBUserClientConfig = {
  cacheMaxAge?: number;
  cacheSize?: number;
  fetchUser: (userId: string) => Promise<BB.UserType>;
  onError?: (e: Error) => void;
};

// eslint-disable-next-line import/no-default-export
export default class UserClient extends UserProfileClient {
  bbConfig: {
    fetchUser: (userId: string) => Promise<BB.UserType>;
    onError?: (e: Error) => void;
  };

  constructor(extendedConfig: BBUserClientConfig) {
    const { fetchUser, onError, ...config } = extendedConfig;
    super({ ...config, url: '' });
    this.bbConfig = { fetchUser, onError };
  }

  getReadableAppUserKind(
    kind: AppUserKind
  ): FormattedMessage.MessageDescriptor {
    switch (kind) {
      case 'repository_access_token': {
        return appUserKindMessages.repositoryAccessToken;
      }
      case 'project_access_token': {
        return appUserKindMessages.projectAccessToken;
      }
      case 'workspace_access_token': {
        return appUserKindMessages.workspaceAccessToken;
      }
      case 'forge_app': {
        return appUserKindMessages.forgeApp;
      }
      default: {
        return appUserKindMessages.unknownAppUser;
      }
    }
  }

  transformUser(user: BB.UserType) {
    if (user.type === 'team') {
      return {
        disabledAccountMessage: (
          <FormattedMessage {...messages.teamProfileCardMessage} />
        ),
        fullName: user.display_name,
        status: 'inactive',
        hasDisabledAccountLozenge: false,
        isBot: false,
        isNotMentionable: true,
      };
    }

    if (user.account_status === 'UNKNOWN') {
      throw new Error();
    }

    if (user.type === 'app_user') {
      const appUser = user as AppUser;
      const appUserLozenges = [{ text: 'APP USER', appearance: 'new' }];
      if (user.account_status === 'inactive') {
        appUserLozenges.push({ text: 'DEACTIVATED', appearance: 'default' });
      }
      const userKindMessage = this.getReadableAppUserKind(appUser.kind);
      return {
        fullName: appUser.display_name,
        status: 'active', // use 'active' status so that we can show `meta` details
        meta: <FormattedMessage {...userKindMessage} />,
        isBot: false, // use isBot is false so that we can show `meta` details
        isNotMentionable: true,
        avatarUrl: appUser.links.avatar.href,
        customLozenges: appUserLozenges,
      };
    }

    if (!user.account_id) {
      return {
        disabledAccountMessage: (
          <FormattedMessage
            {...messages.unmigratedAtlassianAccountProfileCardMessage}
          />
        ),
        fullName: user.display_name,
        status: 'inactive',
        hasDisabledAccountLozenge: false,
        hideProfileLink: true,
        isBot: false,
        isNotMentionable: true,
      };
    }

    // Attempt to generate a timestring based on the `zoneinfo` field, which can be
    // a value that is not IANA-compliant (meaning not supported by `Intl.DateTimeFormat`)
    let timestring;
    if (user.zoneinfo) {
      try {
        timestring = new Intl.DateTimeFormat(undefined, {
          timeZone: user.zoneinfo,
          hour: 'numeric',
          minute: 'numeric',
          weekday: 'short',
        }).format(Date.now());
      } catch (e) {
        if (this.bbConfig.onError) {
          this.bbConfig.onError(e);
        }
      }
    }

    let companyName = user.organization || user.department || undefined;
    if (user.organization && user.department) {
      companyName = `${user.organization} (${user.department})`;
    }

    const commonFields = {
      isBot: false,
      isNotMentionable: false,
      avatarUrl: user.links.avatar.href,
      companyName,
      fullName: user.display_name,
      location: user.location ? user.location : undefined,
      status: user.account_status ? user.account_status : 'inactive',
      timestring,
    };

    // omit `nickname` so the profile card doesn't render it in parens beside `fullName`
    // pass it as `meta` instead to render beneath where the job title would normally go
    if (user.display_name !== user.nickname) {
      return {
        ...commonFields,
        meta: user.nickname,
      };
    }

    return {
      ...commonFields,
      nickname: user.nickname,
    };
  }

  makeRequest(
    _cloudId: string,
    userId: string
  ): Promise<ProfileCardClientData> {
    return this.bbConfig.fetchUser(userId).then(this.transformUser.bind(this));
  }
}
