import { Link } from 'src/components/types';
import { ISO8601DateString } from 'src/types/pull-request';

import { ApiCommentOrPlaceholder } from '../conversation-provider/types';
import { CommentThread } from '../conversation/src/components/comment-tree';

export enum ActivityEntryTypes {
  CommentStart = 'comment-start',
  CommentReplies = 'comment-replies',
  TaskCreated = 'task-created',
  TaskResolved = 'task-resolved',
  TasksCreated = 'tasks-created',
  TasksResolved = 'tasks-resolved',
  Commit = 'update',
  StatusChange = 'status-change',
  Approval = 'approval',
  AttachmentAdded = 'attachment-added',
  ChangesRequested = 'changes-requested',
  DraftChange = 'draft-change',
}

export type OverviewCommentEntry = {
  readonly type: 'comment-start';
  actor: BB.User;
  event: CommentThread;
  timestamp: Date;
  latestComment: ApiCommentOrPlaceholder;
};

export type TitleChangeEntry = {
  readonly type: 'title-change';
  actor: ApiUserField;
  event: Update;
  lastTitle: string;
  timestamp: Date;
};

export type DescriptionChangeEntry = {
  readonly type: 'description-change';
  actor: ApiUserField;
  event: Update;
  timestamp: Date;
};

export type ReviewersAddedEntry = {
  readonly type: 'reviewers-added';
  actor: ApiUserField;
  event: Update;
  timestamp: Date;
};

export type CommitEntryHashItem = {
  hash: string | null;
  parentHash: string | null;
  url: string | null;
};

export type CommitEntry = {
  readonly type: 'update';
  actor: ApiUserField;
  event: Update;
  hashes: CommitEntryHashItem[];
  timestamp: Date;
};

export const isCommitEntry = (entry: any): entry is CommitEntry => {
  return (
    entry && 'type' in entry && entry.type === 'update' && 'hashes' in entry
  );
};

export const isReviewersAddedEntry = (
  entry: any
): entry is ReviewersAddedEntry => {
  return entry && 'type' in entry && entry.type === 'reviewers-added';
};

export const isTasksCreatedEntry = (entry: any): entry is TasksCreatedEntry => {
  return entry && 'type' in entry && entry.type === 'tasks-created';
};

export const isDescriptionChangeEntry = (
  entry: any
): entry is DescriptionChangeEntry => {
  return entry && 'type' in entry && entry.type === 'description-change';
};

export const isTasksResolvedEntry = (
  entry: any
): entry is TasksResolvedEntry => {
  return entry && 'type' in entry && entry.type === 'tasks-resolved';
};

export type StatusChangeEntry = {
  readonly type: 'status-change';
  actor: ApiUserField;
  event: Update;
  timestamp: Date;
};

export type DraftChangeEntry = {
  readonly type: 'draft-change';
  actor: ApiUserField;
  event: Update;
  timestamp: Date;
};

export type ApprovalEntry = {
  readonly type: 'approval';
  actor: ApiUserField;
  event: Approval;
  timestamp: Date;
};

export type RequestChangesEntry = {
  readonly type: 'changes-requested';
  actor: ApiUserField;
  event: ChangesRequested;
  timestamp: Date;
};

export type TasksCreatedEntry = {
  readonly type: 'tasks-created';
  actor: ApiUserField;
  event: TaskActivity[];
  timestamp: Date;
};

export type TasksResolvedEntry = {
  readonly type: 'tasks-resolved';
  actor: ApiUserField;
  event: TaskActivity[];
  timestamp: Date;
};

export type AttachmentEntryItem = {
  name: string;
  uuid: string;
};

export type AttachmentEntry = {
  readonly type: 'attachment-added';
  actor: BB.User;
  event: AttachmentActivity;
  items: AttachmentEntryItem[];
  timestamp: Date;
};

export const isAttachmentEntry = (
  entry: ActivityOverviewEntry
): entry is AttachmentEntry => {
  return (
    entry &&
    'type' in entry &&
    entry.type === 'attachment-added' &&
    'items' in entry
  );
};

// Structures for React component consumption
export type ActivityOverviewEntry =
  | ApprovalEntry
  | RequestChangesEntry
  | StatusChangeEntry
  | CommitEntry
  | DescriptionChangeEntry
  | TitleChangeEntry
  | ReviewersAddedEntry
  | AttachmentEntry
  | OverviewCommentEntry
  | TasksCreatedEntry
  | TasksResolvedEntry
  | DraftChangeEntry;

// Has all the required fields of our BB.User but should
// also have html link and account_id.
export type ApiUserField = BB.User & {
  account_id: string;
  links: { html: Link };
};

type ApiCommitField = {
  commit: {
    type: 'commit';
    hash: string;
    links: {
      self: Link;
      html: Link;
    };
    parents?: {
      hash: string;
    }[];
  } | null;
  branch: {
    name: string;
  };
  repository: {
    name: string;
    type: 'repository';
    full_name: string;
    links: {
      self: Link;
      html: Link;
      avatar: Link;
    };
    uuid: string;
  } | null;
};

type Update = {
  update: {
    description: string;
    title: string;
    destination: ApiCommitField;
    reason: string;
    source: ApiCommitField;
    state: BB.PullRequestState;
    // the html link should be there in this response
    author: ApiUserField;
    date: ISO8601DateString;
    draft?: boolean;
    changes: {
      title?: {
        old: string;
        new: string;
      };
      description?: {
        old: string;
        new: string;
      };
      status?: {
        old: string;
        new: string;
      };
      reviewers?: {
        added?: ApiUserField[];
        removed?: ApiUserField[];
      };
    };
  };
  pull_request: ApiPullRequestField;
};

type Comment = {
  comment: {
    content: {
      raw: string;
      // Not sure if this is an enumeration?
      markup: 'markdown' | string;
      html: string;
      // Not sure if this is an enumeration?
      type: 'rendered' | string;
    };
    created_on: ISO8601DateString;
    deleted: boolean;
    id: number;
    links: {
      self: Link;
      html: Link;
    };
    parent?: {
      id: number;
      links: {
        self: Link;
        html: Link;
      };
    };
    pullrequest: ApiPullRequestField;
    type: 'pullrequest_comment';
    updated_on: ISO8601DateString;
    user: ApiUserField;
  };
  pull_request: ApiPullRequestField;
};

type ApiPullRequestField = {
  type: 'pullrequest';
  id: number;
  links: {
    self: Link;
    html: Link;
  };
  title: string;
};

type Approval = {
  approval: {
    date: ISO8601DateString;
    pullrequest: ApiPullRequestField;
    user: ApiUserField;
  };
  pull_request: ApiPullRequestField;
};

type ChangesRequested = {
  changes_requested: {
    date: ISO8601DateString;
    pullrequest: ApiPullRequestField;
    user: ApiUserField;
  };
  pull_request: ApiPullRequestField;
};

type TaskActivity = {
  task: {
    id: number;
    actor: ApiUserField;
    action: string;
    action_on: ISO8601DateString;
    task: BB.Task;
  };
  pull_request: ApiPullRequestField;
};

type AttachmentActivity = {
  attachment: {
    created_on: ISO8601DateString;
    links: {
      self: Link;
    };
    name: string;
    uploaded_by: BB.User;
    uuid: string;
  };
  pull_request: ApiPullRequestField;
};

export interface ActivityApi {
  Comment: Comment;
  Approval: Approval;
  ChangesRequested: ChangesRequested;
  Update: Update;
  TaskActivity: TaskActivity;
  AttachmentActivity: AttachmentActivity;
}

export type ActivityApiResponse = ActivityApi[keyof ActivityApi];

export const isUpdateActivity = (item: ActivityApiResponse): item is Update => {
  return 'update' in item;
};

export const isApprovalActivity = (
  item: ActivityApiResponse
): item is Approval => {
  return 'approval' in item;
};

export const isChangesRequestedActivity = (
  item: ActivityApiResponse
): item is ChangesRequested => {
  return 'changes_requested' in item;
};

export const isTaskActivity = (
  item: ActivityApiResponse
): item is TaskActivity => {
  return 'task' in item;
};

export const isNotCommentActivity = (
  item: ActivityApiResponse
): item is Approval | Update | TaskActivity | ChangesRequested => {
  return !('comment' in item);
};

export const isAttachmentActivity = (
  item: ActivityApiResponse
): item is AttachmentActivity => {
  return 'attachment' in item;
};
