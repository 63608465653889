import React, { ComponentType, PureComponent, ReactElement } from 'react';

import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';

import { FeedbackFlag } from '@atlaskit/feedback-collector';
import { FlagGroup } from '@atlaskit/flag';
import { EngagementMessages } from '@atlassiansox/engagekit-ts';

import { dismissFlag } from 'src/redux/flags';
import {
  ComponentFlagId,
  ComponentFlagProps,
  FlagObject,
  SimpleFlagObject,
} from 'src/redux/flags/types';
import CommitsListUpdatedFlag from 'src/sections/global/components/flags/commits-list-updated';
import {
  CreateBranchErrorFlag,
  LoadRepositoriesErrorFlag,
} from 'src/sections/global/components/flags/create-branch/create-branch-error-flag';
import { CreateBranchSuccessFlag } from 'src/sections/global/components/flags/create-branch/create-branch-success-flag';
import { RepoMaintenanceFlag } from 'src/sections/global/components/flags/repo-maintenance/repo-maintenance-flag';
import { DefaultGroupIntroFlag } from 'src/sections/workspace/sections/settings/components/default-group-intro-flag/default-group-intro-flag';
import { BucketState } from 'src/types/state';
import { publishFact, publishUiEvent } from 'src/utils/analytics/publish';

import {
  AUTOREVIEW_DOGFOODING_FLAG_ID,
  AutoreviewDogfoodingFlag,
} from '../components/flags/autoreview-dogfooding/autoreview-dogfooding-flag';
import { CompareBranchesErrorFlag } from '../components/flags/compare-branches/compare-branches-error-flag';
import { CompareBranchesSuccessFlag } from '../components/flags/compare-branches/compare-branches-success-flag';
import { CompareBranchesTimeoutFlag } from '../components/flags/compare-branches/compare-branches-timeout-flag';
import CreateWorkspaceErrorFlag from '../components/flags/create-workspace/create-workspace-error-flag';
import { DeleteBranchErrorFlag } from '../components/flags/delete-branch/delete-branch-error-flag';
import { DeleteBranchSuccessFlag } from '../components/flags/delete-branch/delete-branch-success-flag';
import DeleteRepositoryErrorFlag from '../components/flags/delete-repository/delete-repository-error-flag';
import DeleteRepositorySuccessFlag from '../components/flags/delete-repository/delete-repository-success-flag';
import { DiffSettingsErrorFlag } from '../components/flags/diff-settings-error-flag';
import { LoadContextErrorFlag } from '../components/flags/diff/load-context-error-flag';
import { LoadContextForbiddenNotFoundFlag } from '../components/flags/diff/load-context-forbidden-not-found-flag';
import { GiveFeedbackSuccessFlag } from '../components/flags/feedback/give-feedback-success-flag';
import { FileModeOnboardingFlag } from '../components/flags/file-mode-onboarding/file-mode-onboarding-flag';
import { InternalNewPageFlag } from '../components/flags/internal-new-page-flag/internal-new-page-flag';
import { CreateJiraIssueSuccessFlag } from '../components/flags/jira/create-jira-issue-success-flag';
import MarketingConsent from '../components/flags/marketing-consent/marketing-consent-flag';
import OfflineFlag from '../components/flags/offline/offline';
import OnlineFlag from '../components/flags/offline/online';
import {
  PullRequestDraftReviewers,
  PULL_REQUEST_DRAFT_REVIEWERS_FLAG_ID,
} from '../components/flags/pull-request-draft-reviewers/pull-request-draft-reviewers';
import { PullRequestUpdatedFlag } from '../components/flags/pull-request-updated-flag';
import { PullRequestRetargetErrorFlag } from '../components/flags/pull-request/pull-request-retarget-error-flag';
import { PullRequestRetargetSuccessFlag } from '../components/flags/pull-request/pull-request-retarget-success-flag';
import {
  PullRequestReviewDiscardErrorFlag,
  PullRequestReviewSubmitErrorFlag,
} from '../components/flags/pull-request/pull-request-review-flags';
import { RevertPullRequestErrorFlag } from '../components/flags/revert-pull-request/revert-pull-request-error-flag';
import SimpleFlag from '../components/flags/simple/simple-flag';
import { SiteMessageFlag } from '../components/flags/site-message/site-message-flag';
import {
  SyntaxHighlightingFlag,
  SYNTAX_HIGHLIGHTING_FLAG_ID,
} from '../components/flags/syntax-highlighting-flag';
import { UserAiOnboardingFlag } from '../components/flags/user-ai-onboarding/user-ai-onboarding-flag';
import UserNeedsAccessWarningFlag, {
  USER_NEEDS_ACCESS_FLAG_ID,
} from '../components/flags/user-needs-access';
import { FlagDismissed } from '../facts';

const flagRegistry: {
  [key in ComponentFlagId]:
    | ComponentType<ComponentFlagProps>
    | typeof FeedbackFlag;
} = {
  'create-branch-load-repositories-error': LoadRepositoriesErrorFlag,
  'create-branch-success': CreateBranchSuccessFlag,
  'create-branch-error': CreateBranchErrorFlag,
  'create-workspace-error': CreateWorkspaceErrorFlag,
  'delete-branch-success': DeleteBranchSuccessFlag,
  'delete-branch-error': DeleteBranchErrorFlag,
  'compare-branches-success': CompareBranchesSuccessFlag,
  'compare-branches-error': CompareBranchesErrorFlag,
  'compare-branches-timeout': CompareBranchesTimeoutFlag,
  'marketing-consent': MarketingConsent,
  'site-message': SiteMessageFlag,
  'diff-settings-error': DiffSettingsErrorFlag,
  'file-mode-onboarding': FileModeOnboardingFlag,
  'pull-request-updated': PullRequestUpdatedFlag,
  'commits-list-updated': CommitsListUpdatedFlag,
  'load-context-error': LoadContextErrorFlag,
  'load-context-forbidden-not-found': LoadContextForbiddenNotFoundFlag,
  'network-offline': OfflineFlag,
  'network-online': OnlineFlag,
  'revert-pull-request-error': RevertPullRequestErrorFlag,
  'delete-repository-success': DeleteRepositorySuccessFlag,
  'error-cannot-be-deleted-sox': DeleteRepositoryErrorFlag,
  'create-jira-issue-success': CreateJiraIssueSuccessFlag,
  'horizontal-nav-feedback-button': GiveFeedbackSuccessFlag,
  [USER_NEEDS_ACCESS_FLAG_ID]: UserNeedsAccessWarningFlag,
  'pull-request-retarget-success': PullRequestRetargetSuccessFlag,
  'pull-request-retarget-error': PullRequestRetargetErrorFlag,
  [SYNTAX_HIGHLIGHTING_FLAG_ID]: SyntaxHighlightingFlag,
  'default-group-intro': DefaultGroupIntroFlag,
  'internal-new-page': InternalNewPageFlag,
  'user-ai-onboarding': UserAiOnboardingFlag,
  'discard-review-error': PullRequestReviewDiscardErrorFlag,
  'submit-review-error': PullRequestReviewSubmitErrorFlag,
  'repo-maintenance-flag': RepoMaintenanceFlag,
  [AUTOREVIEW_DOGFOODING_FLAG_ID]: AutoreviewDogfoodingFlag,
  [PULL_REQUEST_DRAFT_REVIEWERS_FLAG_ID]: PullRequestDraftReviewers,
};

const mapDispatchToProps = {
  dismissFlag,
};

const mapStateToProps = (state: BucketState) => ({
  flags: state.flags,
});

type Props = {
  dismissFlag: (flagId: string | ComponentFlagId) => void;
  flags: FlagObject[];
};

const isComponentFlagId = (id: string): id is ComponentFlagId =>
  id in flagRegistry;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class Flags extends PureComponent<Props> {
    handleDismissed = (id: string) => {
      const { flags } = this.props;
      const flag = flags.find(f => f.id === id);

      if (!flag) {
        return;
      }

      publishFact(new FlagDismissed({ flag_id: flag.id }));
      publishUiEvent({
        action: 'dismissed',
        actionSubject: 'flag',
        actionSubjectId: 'bitbucketFlag',
        source: 'flag',
        attributes: {
          flag_id: flag.id,
        },
      });

      const simpleFlag = flag as SimpleFlagObject;

      if (typeof simpleFlag.onDismissed === 'function') {
        simpleFlag.onDismissed(simpleFlag.id);
      }

      this.props.dismissFlag(flag.id);
    };

    renderFlag = (flagObj: FlagObject) => {
      if (flagObj.type === 'component') {
        let FlagComponent =
          flagObj.componentId && flagRegistry[flagObj.componentId];
        if (!FlagComponent && isComponentFlagId(flagObj.id)) {
          FlagComponent = flagRegistry[flagObj.id];
        }
        if (!FlagComponent) {
          Sentry.withScope(scope => {
            scope.setTag('flagId', flagObj.id);
            Sentry.captureMessage('UNKNOWN_FLAG');
          });

          // eslint-disable-next-line no-console
          console.warn(`UNKNOWN_FLAG: ${flagObj.id}`);
          return null;
        }

        return (
          <FlagComponent
            id={flagObj.id}
            key={flagObj.id}
            props={flagObj.props}
            handleDismissed={this.handleDismissed}
          />
        );
      }

      return (
        <SimpleFlag
          {...flagObj}
          key={flagObj.id}
          handleDismissed={this.handleDismissed}
        />
      );
    };

    render() {
      const { flags } = this.props;
      const renderedFlags = flags
        .map(this.renderFlag)
        .filter((f): f is ReactElement => f !== null);
      if (renderedFlags.length) {
        return (
          <FlagGroup onDismissed={this.handleDismissed}>
            {renderedFlags}
          </FlagGroup>
        );
      } else {
        // EngagementMessages uses their own FlagGroup. If we rendered both this and the above at the same time,
        // and both were showing flags, they would overlap. So render our own first, and if there are none, render the
        // ones from engagement platform.
        return <EngagementMessages />;
      }
    }
  }
);
