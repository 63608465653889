import { stringify } from 'qs';
import { createResource, RouterContext } from 'react-resource-router';

import { NavigationData } from 'src/types/navigation';
import urls from 'src/urls/repositories';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

type RepositoryNavigation = NavigationData & {
  repository: BB.Repository;
};

export const repositoryNavigationResource = createResource({
  type: 'REPOSITORY_NAVIGATION',
  getKey: ({ match }: RouterContext) => {
    if (match.params.repositoryFullSlug) {
      return `repository:${match.params.repositoryFullSlug}`;
    } else {
      return `repository:${match.params.repositoryOwner}/${match.params.repositorySlug}`;
    }
  },
  maxAge: 1000 * 60 * 10, // 10 minute
  getData: async ({ match }): Promise<RepositoryNavigation | null> => {
    const { repositoryFullSlug, repositoryOwner, repositorySlug } =
      match.params;
    const repo = repositoryFullSlug ?? `${repositoryOwner}/${repositorySlug}`;

    const url = urls.api.internal.navigation(repo);
    // TODO once we start using this for actual navigation data, include menuItems below
    const query = stringify({
      fields: `+repository.landing_page`,
    });
    const response = await fetch(
      authRequest(`${url}?${query}`, { headers: jsonHeaders })
    );

    if (!response.ok) {
      throw new Error(
        `Error fetching repository navigation data (${response.status}): ${response.statusText}`
      );
    }

    return response.json();
  },
});
