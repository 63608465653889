import { toFabricUser } from 'src/components/conversation-provider/utils';
import { CommentThread } from 'src/components/conversation/src/components/comment-tree';
import { toMinimalComment } from 'src/redux/pull-request/utils/comments';

import {
  FabricComment,
  FabricConversation,
  InlineField,
  isApiComment,
  CodeReviewConversation,
  MinimalCodeReviewConversationComment,
  ApiCommentOrPlaceholder,
  ApiToFabricCommentProps,
} from '../types';

import { apiToFabricComment } from './to-comment';

function hasValidChildren(
  currentComment: ApiCommentOrPlaceholder,
  rawComments: ApiCommentOrPlaceholder[]
): boolean {
  // placeholder comments can't have children (but can have a parent)
  if (!isApiComment(currentComment)) {
    return false;
  }

  const children = rawComments.filter(
    comment => comment.parent && comment.parent.id === currentComment.id
  );

  if (children.some(c => !isApiComment(c) || !c.deleted)) {
    // If any immediate children are undeleted/placeholder then we know we need to render
    return true;
  }

  // Check children at depth
  return children.some(childComment =>
    hasValidChildren(childComment, rawComments)
  );
}

export function apiCommentId(
  comment: ApiCommentOrPlaceholder
): string | number {
  return isApiComment(comment) ? comment.id : comment.placeholderId;
}

export function toConversationId(comment: ApiCommentOrPlaceholder): string {
  return `conversation-${apiCommentId(comment)}`;
}

export function conversationIdToTopCommentId(
  conversationId: string
): string | undefined {
  // The conversationId is of the form `conversation-<commentId>`
  // so this value can be used to extract the commentId
  const CONVERSATION_ID_PREFIX = 'conversation-';
  if (conversationId.startsWith(CONVERSATION_ID_PREFIX)) {
    return conversationId.substring(CONVERSATION_ID_PREFIX.length);
  }
  return undefined;
}

/* Note: Order of the incoming comments matters.  Parents must come before
 * children in the array.  The original fetch of the comments returns them
 * in the correct expected order.
 */
export function toConversation(
  rawComments: ApiCommentOrPlaceholder[],
  containerId: string,
  localId?: string
): FabricConversation | undefined {
  if (!rawComments.length) {
    return undefined;
  }

  const [firstComment] = rawComments;
  const conversationId = toConversationId(firstComment);
  // TODO: THIS NEEDS OPTIMIZING
  const children = rawComments.reduce(
    (
      processedComments: FabricComment[],
      currentComment: ApiCommentOrPlaceholder
    ) => {
      const { parent } = currentComment;
      const isTopLevel = !parent;

      // placeholder comments, undeleted comments, deleted comments with undeleted
      // descendants are possible descendants of this conversation
      if (
        !isApiComment(currentComment) ||
        !currentComment.deleted ||
        hasValidChildren(currentComment, rawComments)
      ) {
        const isDescendant = () =>
          processedComments.some(
            fabricComment => !!parent && fabricComment.commentId === parent.id
          );

        const apiToFabricCommentProps: ApiToFabricCommentProps = {
          comment: currentComment,
          conversationId,
        };

        if (isTopLevel || isDescendant()) {
          processedComments.push(apiToFabricComment(apiToFabricCommentProps));
        }
      }

      return processedComments;
    },
    []
  );

  if (!children.length) {
    return undefined;
  }

  return {
    containerId,
    conversationId,
    localId,
    meta: firstComment.inline || ({} as InlineField),
    comments: children,
    createdAt: firstComment.created_on,
  };
}

export function threadToConversation(
  thread: CommentThread
): CodeReviewConversation {
  const conversationId = toConversationId(thread.topComment);
  const comments: MinimalCodeReviewConversationComment[] =
    'id' in thread.topComment
      ? [
          toMinimalComment(thread.topComment),
          ...Array.from(
            thread.commentTree.descendentsOf(thread.topComment.id)
          ).map(c => toMinimalComment(c)),
        ]
      : [toMinimalComment(thread.topComment)];
  return {
    conversationId,
    comments,
    meta: thread.topComment.inline || ({} as InlineField),
    numOfComments: comments.length,
    createdAt: thread.topComment.created_on,
    createdBy: toFabricUser(thread.topComment.user),
  };
}
