import * as Sentry from '@sentry/browser';

import { parse } from '../serializer';
import {
  FabricComment,
  ADF,
  isApiComment,
  ApiToFabricCommentProps,
} from '../types';

import { toFabricUser } from './user-utils';

export function apiToFabricComment(
  props: ApiToFabricCommentProps
): FabricComment {
  const {
    comment,
    conversationId,
    nestedDepth,
    additionalParseOptions = {},
  } = props;

  const {
    created_on: createdAt,
    user,
    parent,
    state,
    content,
    resolution,
    pending,
  } = comment;

  const id = isApiComment(comment) ? comment.id : -1;
  const deleted = isApiComment(comment) ? comment.deleted : false;
  // in-flight comments will use the ADF doc (rich text) for
  // seamless transition upon save
  let doc: { adf?: ADF } = {};
  try {
    if (!deleted) {
      doc = {
        adf:
          'html' in content
            ? parse(content.html, additionalParseOptions)
            : content.doc,
      };
    }
  } catch (e) {
    Sentry.captureException(e);
  }

  return {
    conversationId,
    commentId: id,
    localId: id,
    parentId: parent ? parent.id : undefined,
    createdAt,
    createdBy: toFabricUser(user),
    deleted,
    document: doc,
    state,
    resolution,
    pending,
    nestedDepth,
  };
}
