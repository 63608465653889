import { TaskData } from 'src/components/pipelines/models';

import durationToSeconds from '../utils/durationToSeconds';

const getCommandsV3Duration = (commands: CommandMetadata[]): number => {
  return commands.reduce(
    (total, command) => total + durationToSeconds(command.execution_duration),
    0
  );
};

const getCommandMetadataFields = (commands: CommandMetadata[]) => {
  if (!commands || !commands.length) return {};

  return {
    executionDuration: getCommandsV3Duration(commands),
    commandIds: commands.map(c => c.command_id),
    byteCount: commands.reduce((total, c) => total + c.byte_count, 0),
  };
};

export enum ExecutionPhase {
  'RUNNER_DETAILS' = 'RUNNER_DETAILS',
  'SETUP' = 'SETUP',
  'MAIN' = 'MAIN',
  'AFTER_MAIN' = 'AFTER_MAIN',
  'TEARDOWN' = 'TEARDOWN',
}

export type CommandId = `${ExecutionPhase}_${string}`;

export type CommandMetadata = {
  command_id: CommandId;
  byte_count: number;
  started_on: string;
  completed_on: string;
  execution_duration: string;
};
export type CommandsMetadataResponse = CommandMetadata[];

export class CommandV3 {
  readonly command: string = '';
  readonly executionDuration: number = 0;
  readonly name: string = '';
  readonly executionPhase: ExecutionPhase;
  readonly index: number = -1;
  readonly byteCount: number = 0;
  readonly commandIds: CommandId[] = [];

  constructor(props: Partial<CommandV3> = {}) {
    Object.assign(this, {
      ...props,
    });
    Object.freeze(this);
  }
}

export class MainCommandV3 extends CommandV3 {
  constructor(
    commandMetadata: CommandMetadata,
    commandString: string,
    index: number
  ) {
    super({
      command: commandString,
      name: commandString,
      executionPhase: ExecutionPhase.MAIN,
      index,
      ...getCommandMetadataFields(commandMetadata ? [commandMetadata] : []),
    });
  }
}

export class AfterMainCommandV3 extends CommandV3 {
  constructor(
    commandMetadata: CommandMetadata,
    commandString: string,
    index: number
  ) {
    super({
      command: commandString,
      name: commandString,
      executionPhase: ExecutionPhase.AFTER_MAIN,
      index,
      ...getCommandMetadataFields(commandMetadata ? [commandMetadata] : []),
    });
  }
}

export class RunnerDetailsCommandV3 extends CommandV3 {
  constructor(commandMetadata: CommandMetadata[], index: number) {
    super({
      command: 'Runner details',
      name: 'Runner',
      executionPhase: ExecutionPhase.RUNNER_DETAILS,
      index,
      ...getCommandMetadataFields(commandMetadata),
    });
  }
}

export class SetupCommandV3 extends CommandV3 {
  constructor(commandMetadata: CommandMetadata[], index: number) {
    super({
      command: 'Build setup',
      name: 'Build setup',
      executionPhase: ExecutionPhase.SETUP,
      index,
      ...getCommandMetadataFields(commandMetadata),
    });
  }
}

export class TeardownCommandV3 extends CommandV3 {
  constructor(commandMetadata: CommandMetadata[], index: number) {
    super({
      command: 'Build teardown',
      name: 'Build teardown',
      executionPhase: ExecutionPhase.TEARDOWN,
      index,
      ...getCommandMetadataFields(commandMetadata),
    });
  }
}

export class CommandsMetadata {
  readonly runnerDetailsCommandMetadata: CommandMetadata[];
  readonly setupCommandMetadata: CommandMetadata[];
  readonly teardownCommandMetadata: CommandMetadata[];
  readonly mainCommandsMetadata: CommandMetadata[];
  readonly afterMainCommandsMetadata: CommandMetadata[];

  constructor(commandsMetadata: CommandMetadata[]) {
    Object.assign(this, {
      runnerDetailsCommandMetadata: commandsMetadata.filter(c =>
        c.command_id.includes(ExecutionPhase.RUNNER_DETAILS)
      ),
      setupCommandMetadata: commandsMetadata.filter(c =>
        c.command_id.includes(ExecutionPhase.SETUP)
      ),
      teardownCommandMetadata: commandsMetadata.filter(c =>
        c.command_id.includes(ExecutionPhase.TEARDOWN)
      ),
      mainCommandsMetadata: commandsMetadata.filter(
        c =>
          !c.command_id.includes(ExecutionPhase.AFTER_MAIN) &&
          c.command_id.includes(ExecutionPhase.MAIN)
      ),
      afterMainCommandsMetadata: commandsMetadata.filter(c =>
        c.command_id.includes(ExecutionPhase.AFTER_MAIN)
      ),
    });
    Object.freeze(this);
  }
}

export type StepCommandV3 =
  | SetupCommandV3
  | MainCommandV3
  | TeardownCommandV3
  | AfterMainCommandV3
  | RunnerDetailsCommandV3;

export type LogRangesResponse = {
  execution_phases: TaskData['execution_phases'];
  log_byte_count: number;
};

const getCommandsDuration = (
  commands: { execution_duration: number }[]
): number => {
  return (commands || [])
    .filter(command => command.execution_duration)
    .reduce((prev, current) => prev + current.execution_duration, 0);
};

export class LogRange {
  readonly byte_count: number = 0;
  readonly first_byte_position: number = 0;
  readonly last_byte_position: number = 0;

  constructor(props: Partial<LogRange> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }
}

export class Command {
  readonly command_string: string = '';
  readonly log_range: LogRange = new LogRange();
  readonly execution_duration: any = null;
  readonly command: any = null;
  readonly index: number = -1;

  constructor(props: Partial<Command> = {}) {
    Object.assign(this, {
      ...props,
      log_range: new LogRange(props.log_range),
      index: props.index,
      execution_duration: props.execution_duration
        ? durationToSeconds(props.execution_duration)
        : props.command?.execution_duration
        ? durationToSeconds(props.command.execution_duration)
        : null,
    });
    Object.freeze(this);
  }

  toLegacyModel(): object {
    return {
      command: this.command_string,
      name: this.command_string,
      execution_duration: this.execution_duration,
      log_range: this.log_range,
      index: this.index,
    };
  }
}

export class ScriptCommand {
  readonly command: string = '';
  readonly log_range: LogRange = new LogRange();
  readonly execution_duration: any = null;
  readonly name: string = '';
  readonly execution_phase: ExecutionPhase = ExecutionPhase.MAIN;
  readonly index: number = -1;

  constructor(props: Partial<ScriptCommand> = {}) {
    Object.assign(this, {
      ...props,
      log_range: new LogRange(props.log_range),
    });
    Object.freeze(this);
  }
}

export class SetupCommand extends ScriptCommand {
  constructor(commands: any[] = [], logRange: LogRange, index?: number) {
    super({
      command: 'Build setup',
      name: 'Build setup',
      log_range: new LogRange(logRange),
      execution_duration: getCommandsDuration(commands),
      execution_phase: ExecutionPhase.SETUP,
      index,
    });
  }
}

export class AfterScriptCommand {
  readonly command: string = '';
  readonly log_range: LogRange = new LogRange();
  readonly execution_duration: any = null;
  readonly name: string = '';
  readonly execution_phase: ExecutionPhase = ExecutionPhase.AFTER_MAIN;
  readonly index: number = -1;

  constructor(props: Partial<AfterScriptCommand> = {}) {
    Object.assign(this, {
      ...props,
      log_range: new LogRange(props.log_range),
    });
    Object.freeze(this);
  }
}

export class TeardownCommand extends ScriptCommand {
  constructor(commands: any[] = [], logRange: LogRange, index?: number) {
    super({
      command: 'Build teardown',
      name: 'Build teardown',
      log_range: new LogRange(logRange),
      execution_duration: getCommandsDuration(commands),
      execution_phase: ExecutionPhase.TEARDOWN,
      index,
    });
  }
}

export class RunnerDetailsCommand extends ScriptCommand {
  constructor(commands: any[] = [], logRange: LogRange, index?: number) {
    super({
      command: 'Runner details',
      name: 'Runner',
      log_range: new LogRange(logRange),
      execution_duration: getCommandsDuration(commands),
      execution_phase: ExecutionPhase.RUNNER_DETAILS,
      index,
    });
  }
}

export type StepCommand =
  | SetupCommand
  | ScriptCommand
  | TeardownCommand
  | AfterScriptCommand
  | RunnerDetailsCommand;

export enum CommandToggleOption {
  DOWNLOADED = 'downloadedCommands',
  EXPANDED = 'expandedCommands',
}

export type CommandToggleState =
  | CommandToggleOption.DOWNLOADED
  | CommandToggleOption.EXPANDED;
