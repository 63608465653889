/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import qs from 'qs';
import {
  all,
  call,
  put,
  select,
  takeLatest,
  debounce,
} from 'redux-saga/effects';

import {
  ChildPipelineStep,
  Deployment,
  Environment,
  Step,
} from 'src/components/pipelines/models';
import { LoadingStatus } from 'src/constants/loading-status';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

import {
  STEPS_PAGE_SIZE,
  DELAY_STEP_UPDATED,
  DELAY_LOG_UPDATED,
} from '../../constants';
import envBaseUrl from '../../utils/env-base-url';
import {
  capturePipelinesExceptionWithTags,
  createErrorMessage,
} from '../../utils/sentry';
import {
  REQUEST_REDEPLOY_STEP,
  REQUEST_RERUN_STEPS,
  REQUEST_START_STEP,
  REQUEST_STEP_BRANCH_RESTRICTIONS,
  REQUEST_STEP_PROMOTION,
  REQUEST_STEP_REDEPLOYMENT,
  REQUEST_STEPS,
  STEP_UPDATED,
  REQUEST_RESUME_STAGE_REDEPLOY,
  REQUEST_LOG_RANGES,
  LOG_UPDATED,
  REQUEST_STEP_LOG_RANGE,
  REQUEST_COMMANDS_METADATA,
} from '../actions/pipelines';
import {
  getCurrentPipeline,
  getCurrentStep,
  getFetchedStepBranchRestrictionsMap,
  getStepsMap,
} from '../selectors/pipelines';

import {
  getDeploymentsUrl,
  getDeploymentDashboardUrl,
  getBranchRestrictionsUrl,
  getDeploymentByStepUrl,
} from './deployments';

export const getStepsUrl = (
  repositoryFullSlug: string,
  pipelineUuid: string,
  pipelineRunUuid?: string,
  queryParams: any = {}
) => {
  const allQueryParams = {
    page: 1,
    pagelen: STEPS_PAGE_SIZE,
    fields: '+values.environment.*.*.*,+values.test_report.result.*',
    ...queryParams,
  };
  if (pipelineRunUuid) {
    return `${envBaseUrl(
      '/!api/internal',
      'pipelines',
      true
    )}/repositories/${repositoryFullSlug}/pipelines/${pipelineUuid}/runs/${pipelineRunUuid}/steps?${qs.stringify(
      allQueryParams
    )}`;
  }
  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineUuid}/steps/?${qs.stringify(
    allQueryParams
  )}`;
};

export const getStartStepUrl = (
  repositoryFullSlug: string,
  pipelineUuid: string,
  stepUuid: string
) => {
  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineUuid}/steps/${stepUuid}/start_step`;
};

export const getRerunStepUrl = (
  repositoryFullSlug: string,
  pipelineUuid: string,
  queryParams = {}
) => {
  return `${envBaseUrl(
    '/!api/internal',
    'pipelines',
    true
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineUuid}/runs?${qs.stringify(
    {
      fields: '+target.commit.message,+target.commit.summary.html,+target.*,+*',
      ...queryParams,
    }
  )}`;
};

export const getRedeployStepUrl = (
  repositoryFullSlug: string,
  pipelineUuid: string,
  stepUuid: string
) => {
  return `${envBaseUrl(
    '/!api/internal',
    'pipelines',
    true
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineUuid}/steps/${stepUuid}/redeploy?${qs.stringify(
    {
      fields: '+target.commit.message,+target.commit.summary.html,+target.*,+*',
    }
  )}`;
};

export const getLogRangesUrl = (
  repositoryFullSlug: string,
  pipelineUuid: string,
  stepUuid: string
): string => {
  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineUuid}/steps/${stepUuid}/log-ranges`;
};

export const getCommandsMetadataUrl = (
  repositoryFullSlug: string,
  pipelineUuid: string,
  stepUuid: string | undefined
): string => {
  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineUuid}/steps/${stepUuid}/commands-metadata`;
};

export const getResumeStageRedeployUrl = (
  repositoryFullSlug: string,
  pipelineUuid: string,
  stageIndex: number
) => {
  return getRerunStepUrl(repositoryFullSlug, pipelineUuid, { stageIndex });
};

export function* requestStepsSaga(action: {
  event?: string;
  eventType?: string;
  meta: {
    stepUuid?: string;
    pipelineRunUuid?: string;
    isLogsV3UIRefactorEnabled: boolean;
  };
  pipelineUuid?: string;
  type: string;
  from_ws?: boolean;
}): any {
  const { stepUuid, pipelineRunUuid, isLogsV3UIRefactorEnabled } =
    action.meta || action; // websocket is not grouping params in action
  const { full_name } = yield select(getCurrentRepository);
  const pipeline = yield select(getCurrentPipeline); // the pipeline when init the request
  const from_log_update_ws =
    (action.from_ws || false) && action.type === 'LOG_UPDATED';

  if (!pipeline.uuid) {
    return;
  }
  try {
    const url = getStepsUrl(full_name, pipeline.uuid, pipelineRunUuid, {
      from_log_update_ws,
    });
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);

    const currPipeline = yield select(getCurrentPipeline); // the pipeline when tries to merge data into redux

    if (!currPipeline || currPipeline.uuid !== pipeline.uuid) {
      return;
    }
    yield put({
      type: REQUEST_STEPS.SUCCESS,
      meta: {
        pipelineUuid: pipeline.uuid,
        stepUuid,
        pipelineRunUuid,
        isLogsV3UIRefactorEnabled,
      },
      payload: data,
    });

    return;
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_STEPS.ERROR,
    });
    yield put({
      type: REQUEST_STEPS.ERROR,
      meta: { pipelineUuid: pipeline.uuid, stepUuid },
      payload: e,
    });
  }
}

export function* postRequestStepsSaga(): any {
  const fetchedBranchRestrictionsMap = yield select(
    getFetchedStepBranchRestrictionsMap
  );
  const steps = Object.values(yield select(getStepsMap)) as (
    | Step
    | ChildPipelineStep
  )[];
  const environmentUuids = steps
    .filter(step => !step.isChildStep && step.hasEnvironment)
    .filter(
      step =>
        !step.isChildStep &&
        fetchedBranchRestrictionsMap[step.environmentUuid] !==
          LoadingStatus.Success
    )
    .map((step: Step) => step.environmentUuid);

  if (environmentUuids.length > 0) {
    yield put({
      type: REQUEST_STEP_BRANCH_RESTRICTIONS.REQUEST,
      meta: { environmentUuids },
    });
  }
}

export function* requestStartStepSaga(action: {
  meta: { pipelineUuid: string; stepUuid: string; stageIndex?: number };
  type: string;
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getStartStepUrl(
      full_name,
      action.meta.pipelineUuid,
      action.meta.stepUuid
    );
    const res: Response = yield call(
      fetch,
      authRequest(url, { method: 'POST' })
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    yield put({
      type: REQUEST_START_STEP.SUCCESS,
      meta: action.meta,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_START_STEP.ERROR,
    });
    yield put({
      type: REQUEST_START_STEP.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestRerunStepsSaga(action: {
  meta: { stepUuid: string };
  type: string;
}): any {
  const { full_name } = yield select(getCurrentRepository);
  const pipeline = yield select(getCurrentPipeline);
  try {
    const url = getRerunStepUrl(full_name, pipeline.uuid);
    const res: Response = yield call(
      fetch,
      authRequest(url, { method: 'POST' })
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }

    const data = yield call([res, 'json']);
    yield put({
      type: REQUEST_RERUN_STEPS.SUCCESS,
      meta: action.meta,
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_RERUN_STEPS.ERROR,
    });
    yield put({
      type: REQUEST_RERUN_STEPS.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestRedeployStepSaga(action: {
  meta: { pipelineUuid: string; stepUuid: string; stageIndex?: number };
  type: string;
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getRedeployStepUrl(
      full_name,
      action.meta.pipelineUuid,
      action.meta.stepUuid
    );
    const res: Response = yield call(
      fetch,
      authRequest(url, { method: 'POST' })
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }

    const data = yield call([res, 'json']);
    yield put({
      type: REQUEST_REDEPLOY_STEP.SUCCESS,
      meta: action.meta,
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_REDEPLOY_STEP.ERROR,
    });
    yield put({
      type: REQUEST_REDEPLOY_STEP.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestResumeStageRedeploySaga(action: {
  meta: { pipelineUuid: string; stageIndex: number };
  type: string;
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getResumeStageRedeployUrl(
      full_name,
      action.meta.pipelineUuid,
      action.meta.stageIndex
    );
    const res: Response = yield call(
      fetch,
      authRequest(url, { method: 'POST' })
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }

    const data = yield call([res, 'json']);
    yield put({
      type: REQUEST_RESUME_STAGE_REDEPLOY.SUCCESS,
      meta: action.meta,
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_RESUME_STAGE_REDEPLOY.ERROR,
    });
    yield put({
      type: REQUEST_RESUME_STAGE_REDEPLOY.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestStepPromotionSaga(action: {
  meta: {
    key: string;
    environmentUuid: string;
    isRerunnableStep: boolean;
    pipelineUuid: string;
    stepUuid: string;
  };
  type: string;
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const { pipelineUuid, stepUuid } = action.meta;
    const deploymentsUrl = getDeploymentDashboardUrl(full_name);
    const stepDeploymentsUrl = getDeploymentByStepUrl(
      full_name,
      pipelineUuid,
      stepUuid
    );

    const res = yield all([
      call(fetch, authRequest(deploymentsUrl)),
      call(fetch, authRequest(stepDeploymentsUrl)),
    ]);

    for (const response of res) {
      if (!response.ok) {
        throw new Error(yield createErrorMessage(response));
      }
    }

    const [environments, stepDeployments] = yield all(
      res.map((r: any) => call([r, 'json']))
    );

    const deployment = stepDeployments;

    yield put({
      type: REQUEST_STEP_PROMOTION.SUCCESS,
      meta: action.meta,
      payload: { environments, deployment },
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_STEP_PROMOTION.ERROR,
    });
    yield put({
      type: REQUEST_STEP_PROMOTION.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestStepRedeploymentSaga(action: {
  meta: {
    key: string;
    pipelineUuid: string;
    stepUuid: string;
    environmentUuid: string;
  };
  type: string;
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const { environmentUuid, pipelineUuid, stepUuid } = action.meta;
    const deploymentUrl = getDeploymentByStepUrl(
      full_name,
      pipelineUuid,
      stepUuid
    );

    const latestDeploymentUrl = getDeploymentsUrl(full_name, {
      environment: environmentUuid,
      pagelen: 1,
      fields: '+values.*.*.*.*.*.*',
    });

    const res = yield all([
      call(fetch, authRequest(deploymentUrl)),
      call(fetch, authRequest(latestDeploymentUrl)),
    ]);

    for (const response of res) {
      if (!response.ok) {
        throw new Error(yield createErrorMessage(response));
      }
    }

    const [deploymentUrlData, latestDeploymentUrlData] = yield all(
      res.map((r: any) => call([r, 'json']))
    );

    yield put({
      type: REQUEST_STEP_REDEPLOYMENT.SUCCESS,
      meta: action.meta,
      payload: {
        deployment: new Deployment(deploymentUrlData),
        lastSuccessfulDeployment: new Deployment(
          latestDeploymentUrlData.values?.[0]
        ),
        environment: new Environment(deploymentUrlData?.environment),
      },
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_STEP_REDEPLOYMENT.ERROR,
    });
    yield put({
      type: REQUEST_STEP_REDEPLOYMENT.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestBranchRestrictionsForStepsSaga(action: {
  meta: { environmentUuids: string[] };
  type: string;
}): any {
  const { full_name } = yield select(getCurrentRepository);

  try {
    const url = getBranchRestrictionsUrl(full_name);
    const res: Response = yield call(
      fetch,
      authRequest(url, {
        method: 'POST',
        headers: jsonHeaders,
        body: JSON.stringify({
          environmentUuids: action.meta.environmentUuids,
        }),
      })
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);
    yield put({
      type: REQUEST_STEP_BRANCH_RESTRICTIONS.SUCCESS,
      meta: action.meta,
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_STEP_BRANCH_RESTRICTIONS.ERROR,
    });
    yield put({
      type: REQUEST_STEP_BRANCH_RESTRICTIONS.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestCommandMetadataSaga(action: {
  meta: {
    stepUuid?: string;
    callback?: () => void;
    isLogsV3UIRefactorEnabled: boolean;
  };
  pipelineUuid?: string;
  type: string;
}): any {
  const step = yield select(getCurrentStep);
  const { stepUuid, isLogsV3UIRefactorEnabled } = action.meta || action; // websocket is not grouping params in action
  const { full_name } = yield select(getCurrentRepository);
  const pipeline = yield select(getCurrentPipeline);
  const pipelineUuid = pipeline.uuid || action.pipelineUuid;

  if (!step || !pipelineUuid) {
    return;
  }

  try {
    const url = getCommandsMetadataUrl(full_name, pipelineUuid, stepUuid);
    const res: Response = yield call(fetch, authRequest(url));

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);

    yield put({
      type: REQUEST_COMMANDS_METADATA.SUCCESS,
      meta: { pipelineUuid, stepUuid, isLogsV3UIRefactorEnabled },
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_COMMANDS_METADATA.ERROR,
    });
    yield put({
      type: REQUEST_COMMANDS_METADATA.ERROR,
      meta: { pipelineUuid, stepUuid },
      payload: e,
    });
  }
}

export function* requestUpdatedLog(action: any): any {
  const step = yield select(getCurrentStep);

  if (!step.logV3Enabled) {
    yield requestStepLogRanges(action);
  } else {
    yield requestCommandsMetadata(action);
  }
}

export function* requestStepLogRanges(action: {
  meta: {
    isLogsV3UIRefactorEnabled: boolean;
    stepUuid?: string;
    callback?: () => void;
  };
  pipelineUuid?: string;
  type: string;
  from_ws?: boolean;
}): any {
  const step = yield select(getCurrentStep);

  if (!step) {
    return;
  }

  yield requestLogRangesSaga({
    ...action,
    meta: {
      stepUuid: action.meta?.stepUuid || step.uuid,
    },
  });

  if (typeof action.meta?.callback === 'function') {
    yield action.meta.callback();
  }
}

export function* requestCommandsMetadata(action: {
  meta: {
    isLogsV3UIRefactorEnabled: boolean;
    stepUuid?: string;
    callback?: () => void;
  };
  pipelineUuid?: string;
  type: string;
}): any {
  const step = yield select(getCurrentStep);

  if (!step) {
    return;
  }

  yield requestCommandMetadataSaga({
    ...action,
    meta: {
      stepUuid: action.meta?.stepUuid || step.uuid,
      isLogsV3UIRefactorEnabled: action.meta?.isLogsV3UIRefactorEnabled,
    },
  });

  if (typeof action.meta?.callback === 'function') {
    yield action.meta.callback();
  }
}

export function* requestLogRangesSaga(action: {
  meta: { stepUuid?: string };
  pipelineUuid?: string;
  type: string;
  from_ws?: boolean;
}): any {
  const { stepUuid } = action.meta || action; // websocket is not grouping params in action
  const { full_name } = yield select(getCurrentRepository);
  const pipeline = yield select(getCurrentPipeline); // the pipeline when init the request
  const pipelineUuid = pipeline.uuid || action.pipelineUuid;

  if (!pipelineUuid || !stepUuid) {
    return;
  }

  try {
    const url = getLogRangesUrl(full_name, pipelineUuid, stepUuid);
    const res: Response = yield call(fetch, authRequest(url));

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);

    yield put({
      type: REQUEST_LOG_RANGES.SUCCESS,
      meta: { pipelineUuid, stepUuid },
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_LOG_RANGES.ERROR,
    });
    yield put({
      type: REQUEST_LOG_RANGES.ERROR,
      meta: { pipelineUuid, stepUuid },
      payload: e,
    });
  }
}

export function* stepsSaga(): Generator {
  yield all([
    takeLatest(REQUEST_STEPS.REQUEST, requestStepsSaga),
    takeLatest(REQUEST_STEPS.SUCCESS, postRequestStepsSaga),
    takeLatest(
      REQUEST_STEP_BRANCH_RESTRICTIONS.REQUEST,
      requestBranchRestrictionsForStepsSaga
    ),
    takeLatest(REQUEST_START_STEP.REQUEST, requestStartStepSaga),
    takeLatest(REQUEST_RERUN_STEPS.REQUEST, requestRerunStepsSaga),
    takeLatest(REQUEST_REDEPLOY_STEP.REQUEST, requestRedeployStepSaga),
    takeLatest(
      REQUEST_RESUME_STAGE_REDEPLOY.REQUEST,
      requestResumeStageRedeploySaga
    ),
    takeLatest(REQUEST_STEP_PROMOTION.REQUEST, requestStepPromotionSaga),
    takeLatest(REQUEST_STEP_REDEPLOYMENT.REQUEST, requestStepRedeploymentSaga),
    debounce(DELAY_STEP_UPDATED, STEP_UPDATED, requestStepsSaga),
    debounce(DELAY_LOG_UPDATED, LOG_UPDATED, requestUpdatedLog),
    takeLatest(REQUEST_STEP_LOG_RANGE.REQUEST, requestStepLogRanges),
    takeLatest(REQUEST_COMMANDS_METADATA.REQUEST, requestCommandsMetadata),
  ]);
}
