import { ComponentType } from 'react';

import {
  BrowserHistory,
  Route as ResourceRoute,
  RouteContext as ResourceRouteContext,
} from 'react-resource-router';

export type Route = ResourceRoute & {
  layout?: ComponentType;
};

export type RouteContext = Omit<ResourceRouteContext, 'route'> & {
  route: Route;
};

export type WithRouter = RouteContext & { history: BrowserHistory };

export type RouteComponentProps = WithRouter;

export type Routes = Route[];

export type SupportedPath = Pick<Route, 'name' | 'path' | 'exact'>;

export type RouteConfig = {
  [key: string]: Pick<Route, 'component' | 'resources' | 'layout'>;
};

export type RouteFeatures = {
  isWorkspaceSettingsIndexEnabled: boolean;
  isNavigationResourceSwitchEnabled: boolean;
  isRepoAdminAliasesEnabled: boolean;
  isRepoAdminComplianceEnabled: boolean;
  isPipelinesTestManagementEnabled: boolean;
  isAccountSettingsSSHKeysEnabled: boolean;
  isFrontbucketDownloadsEnabled: boolean;
  isFrontbucketBranchCompareEnabled: boolean;
  isRepoSourceFileEditEnabled: boolean;
  isRepoSourceFileAnnotateEnabled: boolean;
  isRepoSourceAddFileEnabled: boolean;
  isRepoIssuesEnabled: boolean;
  isAutoreviewSettingsPageEnabled: boolean;
};

export enum ROUTE_NAME {
  ROOT = 'ROOT',

  ACCOUNT_SETTINGS_IFRAME = 'ACCOUNT_SETTINGS_IFRAME',
  ACCOUNT_SETTINGS_GPG_KEYS = 'ACCOUNT_SETTINGS_GPG_KEYS',
  ACCOUNT_SETTINGS_SSH_KEYS = 'ACCOUNT_SETTINGS_SSH_KEYS',

  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
  GLOBAL_ACCOUNT_PLAN_SELECTOR = 'GLOBAL_ACCOUNT_PLAN_SELECTOR',
  GLOBAL_ACCOUNT_PAYMENT = 'GLOBAL_ACCOUNT_PAYMENT',
  GLOBAL_BRANCH_CREATE = 'GLOBAL_BRANCH_CREATE',
  GLOBAL_ACCOUNT_WORKSPACES = 'GLOBAL_ACCOUNT_WORKSPACES',
  GLOBAL_ATTRIBUTIONS_PAGE = 'GLOBAL_ATTRIBUTIONS_PAGE',

  PROFILE_REPOSITORIES = 'PROFILE_REPOSITORIES',
  PROJECT_REPOSITORIES = 'PROJECT_REPOSITORIES',
  PROJECT_SETTINGS_DETAILS = 'PROJECT_SETTINGS_DETAILS',
  PROJECT_SETTINGS_ACCESS_KEYS = 'PROJECT_SETTINGS_ACCESS_KEYS',
  PROJECT_SETTINGS_PERMISSIONS = 'PROJECT_SETTINGS_PERMISSIONS',
  PROJECT_SETTINGS_DEFAULT_REVIEWERS = 'PROJECT_SETTINGS_DEFAULT_REVIEWERS',
  REPOSITORY_ADMIN_REVIEW_GROUPS = 'REPOSITORY_ADMIN_REVIEW_GROUPS',
  PROJECT_SETTINGS_BRANCHING_MODEL = 'PROJECT_SETTINGS_BRANCHING_MODEL',
  PROJECT_SETTINGS_MERGE_STRATEGIES = 'PROJECT_SETTINGS_MERGE_STRATEGIES',
  PROJECT_SETTINGS_BRANCH_RESTRICTIONS = 'PROJECT_SETTINGS_BRANCH_RESTRICTIONS',
  PROJECT_SETTINGS_ACCESS_TOKENS = 'PROJECT_SETTINGS_ACCESS_TOKENS',
  PROJECT_SETTINGS_CUSTOM_MERGE_CHECKS = 'PROJECT_SETTINGS_CUSTOM_MERGE_CHECKS',

  REPOSITORY_REDIRECT = 'REPOSITORY_REDIRECT',
  REPOSITORY_SOURCE = 'REPOSITORY_SOURCE',
  REPOSITORY_SOURCE_ADD_FILE = 'REPOSITORY_SOURCE_ADD_FILE',
  REPOSITORY_SOURCE_DIFF = 'REPOSITORY_SOURCE_DIFF',
  REPOSITORY_COMMITS = 'REPOSITORY_COMMITS',
  REPOSITORY_COMMIT = 'REPOSITORY_COMMIT',
  REPOSITORY_COMMITS_HISTORY = 'REPOSITORY_COMMITS_HISTORY',
  REPOSITORY_CONNECT_PAGE = 'REPOSITORY_CONNECT_PAGE',
  REPOSITORY_DOWNLOADS = 'REPOSITORY_DOWNLOADS',
  REPOSITORY_FORGE_PAGE = 'REPOSITORY_FORGE_PAGE',
  REPOSITORY_FILE_HISTORY = 'REPOSITORY_FILE_HISTORY',
  REPOSITORY_JIRA_VIEW = 'REPOSITORY_JIRA_VIEW',
  REPOSITORY_CODE_REVIEW = 'REPOSITORY_CODE_REVIEW',
  REPOSITORY_CREATE_PR = 'REPOSITORY_CREATE_PR',
  REPOSITORY_BRANCH_CREATE = 'REPOSITORY_BRANCH_CREATE',
  REPOSITORY_BRANCH_DETAIL = 'REPOSITORY_BRANCH_DETAIL',
  REPOSITORY_BRANCHES_LIST = 'REPOSITORY_BRANCHES_LIST',
  REPOSITORY_PULL_REQUESTS = 'REPOSITORY_PULL_REQUESTS',
  REPOSITORY_COLD_STORAGE = 'REPOSITORY_COLD_STORAGE',
  REPOSITORY_SECURITY = 'REPOSITORY_SECURITY',
  REPOSITORY_SECURITY_LEARN_MORE = 'REPOSITORY_SECURITY_LEARN_MORE',
  REPOSITORY_DEPLOYMENTS = 'REPOSITORY_DEPLOYMENTS',
  REPOSITORY_PIPELINES = 'REPOSITORY_PIPELINES',
  REPOSITORY_PIPELINES_RESULT = 'REPOSITORY_PIPELINES_RESULT',
  REPOSITORY_TEST_MANAGEMENT = 'REPOSITORY_TEST_MANAGEMENT',
  REPOSITORY_TEST_EXECUTION = 'REPOSITORY_TEST_EXECUTION',
  REPOSITORY_ISSUES = 'REPOSITORY_ISSUES',
  REPOSITORY_SINGLE_ISSUE = 'REPOSITORY_SINGLE_ISSUE',
  REPOSITORY_CREATE_ISSUE = 'REPOSITORY_CREATE_ISSUE',

  REPOSITORY_ADMIN_DETAIL = 'REPOSITORY_ADMIN_DETAIL',
  REPOSITORY_ADMIN_PERMISSIONS = 'REPOSITORY_ADMIN_PERMISSIONS',
  REPOSITORY_ADMIN_ACCESS_KEYS = 'REPOSITORY_ADMIN_ACCESS_KEYS',
  REPOSITORY_ADMIN_COMMITTERS = 'REPOSITORY_ADMIN_COMMITTERS',
  REPOSITORY_ADMIN_COMPLIANCE = 'REPOSITORY_ADMIN_COMPLIANCE',
  REPOSITORY_ADMIN_BRANCH_RESTRICTIONS = 'REPOSITORY_ADMIN_BRANCH_RESTRICTIONS',
  REPOSITORY_ADMIN_BRANCHING_MODEL = 'REPOSITORY_ADMIN_BRANCHING_MODEL',
  REPOSITORY_ADMIN_MERGE_CHECKS = 'REPOSITORY_ADMIN_MERGE_CHECKS',
  REPOSITORY_ADMIN_MERGE_STRATEGIES = 'REPOSITORY_ADMIN_MERGE_STRATEGIES',
  REPOSITORY_ADMIN_DEFAULT_REVIEWERS = 'REPOSITORY_ADMIN_DEFAULT_REVIEWERS',
  REPOSITORY_ADMIN_ACCESS_TOKENS = 'REPOSITORY_ADMIN_ACCESS_TOKENS',
  REPOSITORY_ADMIN_ISSUES_MIGRATION = 'REPOSITORY_ADMIN_ISSUES_MIGRATION',
  REPOSITORY_ADMIN_CONNECT_IFRAME = 'REPOSITORY_ADMIN_CONNECT_IFRAME',
  REPOSITORY_ADMIN_IFRAME = 'REPOSITORY_ADMIN_IFRAME',
  REPOSITORY_ADMIN_FORGE_PAGE = 'REPOSITORY_ADMIN_FORGE_PAGE',

  REPOSITORY_ADMIN_WEBHOOKS_ROOT = 'REPOSITORY_ADMIN_WEBHOOKS_ROOT',
  REPOSITORY_ADMIN_CREATE_WEBHOOK = 'REPOSITORY_ADMIN_CREATE_WEBHOOK',
  REPOSITORY_ADMIN_EDIT_WEBHOOK = 'REPOSITORY_ADMIN_EDIT_WEBHOOK',
  REPOSITORY_ADMIN_VIEW_WEBHOOK = 'REPOSITORY_ADMIN_VIEW_WEBHOOK',
  REPOSITORY_ADMIN_VIEW_WEBHOOK_EVENT = 'REPOSITORY_ADMIN_VIEW_WEBHOOK_EVENT',

  REPOSITORY_ADMIN_PIPELINES_REDIRECT_SETTINGS = 'REPOSITORY_ADMIN_PIPELINES_REDIRECT_SETTINGS',
  REPOSITORY_ADMIN_PIPELINES_REDIRECT_RUNNERS = 'REPOSITORY_ADMIN_PIPELINES_REDIRECT_RUNNERS',
  REPOSITORY_ADMIN_PIPELINES_REDIRECT_DEPLOYMENTS = 'REPOSITORY_ADMIN_PIPELINES_REDIRECT_DEPLOYMENTS',
  REPOSITORY_ADMIN_PIPELINES_SETTINGS = 'REPOSITORY_ADMIN_PIPELINES_SETTINGS',
  REPOSITORY_ADMIN_PIPELINES_OPENID = 'REPOSITORY_ADMIN_PIPELINES_OPENID',
  REPOSITORY_ADMIN_PIPELINES_SSH_KEYS = 'REPOSITORY_ADMIN_PIPELINES_SSH_KEYS',
  REPOSITORY_ADMIN_PIPELINES_RUNNERS = 'REPOSITORY_ADMIN_PIPELINES_RUNNERS',
  REPOSITORY_ADMIN_PIPELINES_DYNAMIC_PIPELINES = 'REPOSITORY_ADMIN_PIPELINES_DYNAMIC_PIPELINES',
  REPOSITORY_ADMIN_PIPELINES_DEPLOYMENTS = 'REPOSITORY_ADMIN_PIPELINES_DEPLOYMENTS',
  REPOSITORY_ADMIN_PIPELINES_VARIABLES = 'REPOSITORY_ADMIN_PIPELINES_VARIABLES',

  REPOSITORY_ADMIN_FEATURES_AUTOREVIEW = 'REPOSITORY_ADMIN_FEATURES_AUTOREVIEW',

  WORKSPACE_CREATE = 'WORKSPACE_CREATE',
  WORKSPACE_PULL_REQUESTS = 'WORKSPACE_PULL_REQUESTS',
  WORKSPACE_SEARCH = 'WORKSPACE_SEARCH',
  WORKSPACE_PROJECTS = 'WORKSPACE_PROJECTS',
  WORKSPACE_REPOSITORIES = 'WORKSPACE_REPOSITORIES',
  WORKSPACE_ROOT = 'WORKSPACE_ROOT',
  WORKSPACE_SETTINGS_CONNECT_ADMIN = 'WORKSPACE_SETTINGS_CONNECT_ADMIN',
  WORKSPACE_SETTINGS_CONNECT_CONFIGURE = 'WORKSPACE_SETTINGS_CONNECT_CONFIGURE',
  WORKSPACE_SETTINGS_IFRAME = 'WORKSPACE_SETTINGS_IFRAME',
  WORKSPACE_SETTINGS_GROUP = 'WORKSPACE_SETTINGS_GROUP',
  WORKSPACE_SETTINGS_GROUPS = 'WORKSPACE_SETTINGS_GROUPS',
  WORKSPACE_SETTINGS_ROOT = 'WORKSPACE_SETTINGS_ROOT',
  WORKSPACE_SETTINGS_USER_DIRECTORY = 'WORKSPACE_SETTINGS_USER_DIRECTORY',
  WORKSPACE_SETTINGS_ATLASSIAN_INTELLIGENCE = 'WORKSPACE_SETTINGS_ATLASSIAN_INTELLIGENCE',
  WORKSPACE_SETTINGS_AUTOREVIEW = 'WORKSPACE_SETTINGS_AUTOREVIEW',
  WORKSPACE_SETTINGS_COMPASS = 'WORKSPACE_SETTINGS_COMPASS',
  WORKSPACE_SETTINGS_MERGE_CHECKS = 'WORKSPACE_SETTINGS_MERGE_CHECKS',
  WORKSPACE_SETTINGS_PERMISSIONS = 'WORKSPACE_SETTINGS_PERMISSIONS',
  WORKSPACE_SETTINGS_ACCESS_TOKENS = 'WORKSPACE_SETTINGS_ACCESS_TOKENS',
  WORKSPACE_SETTINGS_PIPELINES_REDIRECT_SETTINGS = 'WORKSPACE_SETTINGS_PIPELINES_REDIRECT_SETTINGS',
  WORKSPACE_SETTINGS_PIPELINES_REDIRECT_RUNNERS = 'WORKSPACE_SETTINGS_PIPELINES_REDIRECT_RUNNERS',
  WORKSPACE_SETTINGS_PIPELINES_RUNNERS = 'WORKSPACE_SETTINGS_PIPELINES_RUNNERS',
  WORKSPACE_SETTINGS_PIPELINES_VARIABLES = 'WORKSPACE_SETTINGS_PIPELINES_VARIABLES',
  WORKSPACE_SETTINGS_PIPELINES_DYNAMIC_PIPELINES = 'WORKSPACE_SETTINGS_PIPELINES_DYNAMIC_PIPELINES',
  WORKSPACE_SETTINGS_FORGE_PAGE = 'WORKSPACE_SETTINGS_FORGE_PAGE',
  WORKSPACE_OVERVIEW = 'WORKSPACE_OVERVIEW',
}
