import React, { ReactNode, useMemo } from 'react';

import { useResource } from 'react-resource-router';

import { accountNavigationResource } from 'src/sections/account/account-navigation-resource';
import { projectNavigationResource } from 'src/sections/project/project-navigation-resource';
import { repositoryNavigationResource } from 'src/sections/repository/repository-navigation-resource';
import { workspaceNavigationResource } from 'src/sections/workspace/workspace-navigation-resource';
import { NavigationData } from 'src/types/navigation';

export type NavData = {
  menuItems?: NavigationData['menuItems'];
  links?: NavigationData['links'];
  user?: NavigationData['user'];
};

const initialValue = {
  user: undefined,
  links: undefined,
  menuItems: undefined,
};

export const NavDataContext = React.createContext<NavData>(initialValue);

export const NavDataProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { data: accountData } = useResource(accountNavigationResource);
  const { data: workspaceData } = useResource(workspaceNavigationResource);
  const { data: projectData } = useResource(projectNavigationResource);
  const { data: repoData } = useResource(repositoryNavigationResource);

  const currentResourceNavData =
    repoData || projectData || workspaceData || accountData;

  const navDataValue = useMemo(() => {
    const { menuItems, links, user } = currentResourceNavData || initialValue;
    return {
      user,
      links,
      menuItems,
    };
  }, [currentResourceNavData]);

  return (
    <NavDataContext.Provider value={navDataValue}>
      {children}
    </NavDataContext.Provider>
  );
};
