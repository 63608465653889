import qs from 'qs';
import { createResource } from 'react-resource-router';

import { RUNNERS_PAGELEN } from 'src/components/pipelines/constants';
import { Runner } from 'src/components/pipelines/models';
import { request } from 'src/utils/http-request';

import envBaseUrl from '../utils/env-base-url';
import { isIgnoredErrorForSentry } from '../utils/sentry';

export function getPage(queryParamValue?: string): number {
  const page = parseInt(queryParamValue ?? '1', 10);
  if (Number.isNaN(page) || !Number.isFinite(page) || page <= 1) {
    return 1;
  }
  return page;
}

export const REPOSITORY_RUNNERS_PAGE_QUERY_PARAM =
  'repositoryRunnersPage' as const;

type RepositoryRunnersParams = {
  repositoryOwner: string; // workspace
  repositorySlug: string;
};

export interface RunnerApiError {
  type: string;
  error: {
    message: string;
  };
  status: number;
}

export function getRepositoryRunnersUrl(
  params: RepositoryRunnersParams,
  query = {}
): string {
  const { repositoryOwner, repositorySlug } = params;
  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryOwner}/${repositorySlug}/pipelines-config/runners${qs.stringify(
    query,
    { addQueryPrefix: true }
  )}`;
}

function getRequestRepositoryRunnersUrl(
  params: RepositoryRunnersParams,
  query: { [REPOSITORY_RUNNERS_PAGE_QUERY_PARAM]?: string }
) {
  return getRepositoryRunnersUrl(params, {
    page: getPage(query[REPOSITORY_RUNNERS_PAGE_QUERY_PARAM]),
    pagelen: RUNNERS_PAGELEN,
    sort: '-created_on',
  });
}

type RepositoryRunnerByUuidParams = RepositoryRunnersParams & {
  runnerUuid: string;
};

export function getRepositoryRunnerByUuidUrl(
  params: RepositoryRunnerByUuidParams,
  query = {}
): string {
  const { runnerUuid } = params;
  return `${getRepositoryRunnersUrl(params)}/${runnerUuid}${qs.stringify(
    query,
    { addQueryPrefix: true }
  )}`;
}

export function getRepositoryRunnerStateByUuidUrl(
  params: RepositoryRunnerByUuidParams,
  query = {}
): string {
  return `${getRepositoryRunnerByUuidUrl(params)}/state${qs.stringify(query, {
    addQueryPrefix: true,
  })}`;
}

export const WORKSPACE_RUNNERS_PAGE_QUERY_PARAM =
  'workspaceRunnersPage' as const;

type WorkspaceRunnersParams = {
  repositoryOwner?: string;
  workspaceSlug?: string;
};

export function getWorkspaceRunnersUrl(
  params: WorkspaceRunnersParams,
  query = {}
): string {
  const { workspaceSlug = params.repositoryOwner } = params;
  return `${envBaseUrl(
    '/!api/internal'
  )}/workspaces/${workspaceSlug}/pipelines-config/runners${qs.stringify(query, {
    addQueryPrefix: true,
  })}`;
}

function getRequestWorkspaceRunnersUrl(
  params: WorkspaceRunnersParams,
  query: { [WORKSPACE_RUNNERS_PAGE_QUERY_PARAM]?: string }
) {
  return getWorkspaceRunnersUrl(params, {
    page: getPage(query[WORKSPACE_RUNNERS_PAGE_QUERY_PARAM]),
    pagelen: RUNNERS_PAGELEN,
    sort: '-created_on',
  });
}

type WorkspaceRunnerByUuidParams = WorkspaceRunnersParams & {
  runnerUuid: string;
};

export function getWorkspaceRunnerByUuidUrl(
  params: WorkspaceRunnerByUuidParams,
  query = {}
): string {
  const { runnerUuid } = params;
  return `${getWorkspaceRunnersUrl(params)}/${runnerUuid}${qs.stringify(query, {
    addQueryPrefix: true,
  })}`;
}

export function getWorkspaceRunnerStateByUuidUrl(
  params: WorkspaceRunnerByUuidParams,
  query = {}
): string {
  return `${getWorkspaceRunnerByUuidUrl(params)}/state${qs.stringify(query, {
    addQueryPrefix: true,
  })}`;
}

type CreateRunnersResourceOptions = {
  getRequestUrl: (params: {}, query: {}) => string;
  type: string;
};

export type RunnersResource = {
  page: number;
  runners: Runner[];
  total: number;
};

type RunnersResponseBody = {
  page: number;
  size: number; // total number of items
  values: Partial<Runner>[];
  type?: string;
  error?: RunnerApiError;
};

const UNDEFINED_RESPONSE = 'response undefined';

function createRunnerResource({
  getRequestUrl,
  type,
}: CreateRunnersResourceOptions) {
  return createResource<RunnersResource>({
    type,
    getKey: ({ match, query }) => getRequestUrl(match.params, query),
    maxAge: 60000,
    getData: async ({ match, query }) => {
      try {
        const url = getRequestUrl(match.params, query);
        const resp = await request<RunnersResponseBody>(url);
        if (!resp) {
          throw new Error(UNDEFINED_RESPONSE);
        }
        if (resp.type === 'error' && resp.error) {
          throw resp.error;
        }
        const { page, size: total, values } = resp;
        const runners = values?.map(data => new Runner(data));
        return { page, runners, total };
      } catch (e) {
        if (!isIgnoredErrorForSentry(e)) {
          throw e;
        }
        return {
          page: 1,
          runners: [],
          total: 0,
        };
      }
    },
  });
}

export const loadPipelinesRepositoryRunnersResource = createRunnerResource({
  getRequestUrl: getRequestRepositoryRunnersUrl,
  type: 'pipelinesRepositoryRunners',
});

export const loadPipelinesWorkspaceRunnersResource = createRunnerResource({
  getRequestUrl: getRequestWorkspaceRunnersUrl,
  type: 'pipelinesWorkspaceRunners',
});
