import React, { useEffect, useState } from 'react';

import { LoadingComponentProps } from 'react-loadable';

import UFOLoadHold from '@atlaskit/react-ufo/load-hold';

import { GlobalErrorPage } from 'src/components/error-boundary';
import { LoadingPage as BaseLoadingPage } from 'src/sections/global/components/loading-page';
import {
  captureExceptionWithTags,
  captureMessageWithTags,
} from 'src/utils/sentry';

// This is an async function so any exception thrown by Sentry turns into a Promise rejection
// eslint-disable-next-line require-await
const captureError = async (error: LoadingComponentProps['error']) => {
  if (typeof error === 'string') {
    return captureMessageWithTags(error, { component: 'react-loadable' });
  }

  if (error instanceof Error) {
    return captureExceptionWithTags(error, { component: 'react-loadable' });
  }

  // Parcel rejects with an error event, not an Error
  // https://github.com/parcel-bundler/parcel/issues/6052
  // This error event should be coming from a script tag, which will have a
  // "target.src" attribute. We're using optional chaining just in case, but
  // need a ts-ignore as well
  // @ts-ignore
  const src = error instanceof Event ? error.target?.src : undefined;
  if (src) {
    return captureMessageWithTags(
      `Unknown error loading a module with react-loadable: ${
        src || 'unknown src'
      }`,
      { component: 'react-loadable' }
    );
  } else {
    // if we don't know the source, just log the error to see what it is
    return captureMessageWithTags(error, {
      component: 'react-loadable',
    });
  }
};

const useReactLoadableErrorState = (error: LoadingComponentProps['error']) => {
  const [sentryEventId, setSentryEventId] = useState<string | undefined>();

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      captureError(error).then((eventId: string) => {
        setSentryEventId(eventId);
      });
    }
  }, [error, setSentryEventId]);

  return sentryEventId;
};

/* eslint @typescript-eslint/ban-types: "warn" */
export const HiddenLoader: React.FC<LoadingComponentProps> = React.memo(
  props => {
    // We don't need the Sentry id for anything, we just want to capture and log the errors
    useReactLoadableErrorState(props.error);
    return <UFOLoadHold name="hidden-loader" />;
  }
);

/* eslint @typescript-eslint/ban-types: "warn" */
export const LoadingPage: React.FC<LoadingComponentProps> = React.memo(
  props => {
    const { error } = props;
    const sentryEventId = useReactLoadableErrorState(error);

    if (error) {
      return <GlobalErrorPage sentryEventId={sentryEventId} />;
    }

    return <BaseLoadingPage />;
  }
);
